/* You can add global styles to this file, and also import other style files */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

:root {
  //Couleurs primaires Eat'z
  --eatz-vert-fonce: #215250;
  --eatz-corail: #e96e58;
  --eatz-rose-clair: #f2b8b3;
  --eatz-creme: #f8edd8;

  --eatz-background: #f0f5f3;

  //Couleurs secondaires Eat'z
  --eatz-vert-clair: #ceffe9;
  --eatz-corail-clair: #ffddce;
  --eatz-rose-tres-clair: #ffcece;
  --eatz-bleu: #212F52;
  --eatz-bleu-clair: #CED9FF;

  //Nuances Eat'z
  --eatz-vert-tres-fonce : #183a38;

  //Anciennes couleurs Eat'z
  --main-color: #215250;--second-color: #f2b8b3;--eatz-blue: #215250;--eatz-darkBlue: #f2b8b3;

  --cropper-outline-color: rgba(33, 82, 80, 0.7);
  --cropper-overlay-color: rgba(33, 82, 80, 0.7);
}

html {
  // scroll-behavior: smooth;
}

body {
  // -ms-text-size-adjust: 100%;
  // -webkit-text-size-adjust: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--eatz-vert-fonce);
  font-family: 'Karla', sans-serif;
}

*{
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

.page_width{
  position: relative;

  max-width: 1100px;
  margin: auto;
}

h4{
  font-weight: bold;
}

button {
  cursor: pointer;
}

.bi {
  position: relative;
  top: 1px;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.line_span{
  display: block;
  width: 100%;
  border-top: 1px solid #ccc;
}

textarea {
  font-family: Karla, serif;
}

textarea:focus, input:focus{
  outline: none;
}

// text flag system
.info, .alerte, .attention {
  border-radius: 3px;
  padding: 15px;
  line-height: 1.7;
  font-size: 14px;

  li{
    margin-left: 15px;
  }
}

.info {
  border-left: 3px solid var(--eatz-vert-fonce);
  background-color: var(--eatz-vert-clair);
  padding: 15px;
}

.alerte{
  border-left: 3px solid rgba(239, 61, 61, 1);
  background-color: rgba(239, 61, 61, 0.15);
  font-style: italic;
}

.attention{
  background-color: rgba(239, 230, 61, 0.15);
  border-left: 3px solid rgb(239, 230, 61);
}

/*.bounce {
  animation: bounce 3s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7.5px);
  }
}*/
